import React, { useLayoutEffect, useRef, useState, useMemo } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import { Helmet } from "react-helmet"
import LayoutNoStripe from "../components/layoutNoStripe"
import SEO from "../components/seo"
import { SectionSingleProduct } from "../styledComponents/section"
import { StyledLanderMindfulnessCollection } from "../styledComponents/lander/mindfulnessCollection"
import { PromoPatSection } from "../styledComponents/promoPatSection"
import PermissioningProductPromo from "../components/Products/PermissioningProductPromo"
import SingleProductLanderSituLiveOffer from "../components/Products/SingleProductLanderSituLiveOffer"
import HeroBanner from "../components/HeroBanner"
import { StyledAnchorLink, StyledButtonAsLink, StyledButtonLink } from "../styledComponents/button"
import { HeroText, HeroHeader, HeroDescription, HeroHeaderImage } from "../styledComponents/heroBanner"
import { StyledSiteNotificationTimeDuration } from "../styledComponents/siteNotificationTimeDuration"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const SituLiveOffer1 = ({ data: { productAnxiety, productSleep, fullPriceAnxiety, salePriceAnxiety, fullPriceSleep, salePriceSleep, patPrograms }, location }) => {
  const [isNotification, setIsNotification] = useState(false);
  const ref1 = useRef(null);
  const observer = useRef();
  const [isIntersecting, setIsIntersecting] = useState(false);


  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
     
        window.onscroll = function (e) {
          if(window.scrollY > 540){
            setIsNotification(true)
          } else {
            setIsNotification(false)
          }
        }
    }
  }, [])
  

  return (
    <>
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />
    </Helmet>
    <LayoutNoStripe location={location} hideNewsletterSignUp >
    <SEO 
          title="Situ Live - Permission to Reset™ Anxiety" 
          description="Are you suffering with anxiety, Karina has launched her Permission to Reset™ Anxiety program at retailer of the year. Buy it in store now." 
          url="/situlive"
          />
      <HeroBanner
          image="/images/karina-grant-corporate-wellness.jpg"
          backgroundPosition="0 0"
          type="pat"
        >
      <HeroText>
        <HeroHeader isLander="true">
          <HeroHeaderImage src="/images/situlive.png" />
        Permission to Reset™ Anxiety<br />24 hour sale</HeroHeader>
        <StyledAnchorLink inverse="true" className="learn-more" to="/situlive/#info">Find out more</StyledAnchorLink>
      </HeroText>
    </HeroBanner>
    
      {/* <span>24 hour offer </span> */}
        <SectionSingleProduct id="info">
            <SingleProductLanderSituLiveOffer 
              title={productAnxiety.title}
              uri={productAnxiety.uri}
              excerpt={productAnxiety.excerpt}
              featuredImage={productAnxiety.featuredImage}
              content={productAnxiety.content}
              sale={productAnxiety.productData.pricesale}
              fullPrice={fullPriceAnxiety}
              salePrice={salePriceAnxiety}
              sku={fullPriceAnxiety}
              id={productAnxiety.id}
              tag={productAnxiety.productsTag}
              showShareTools={false}
              stripeBuyNowURL="https://buy.stripe.com/fZe8wB9rp9R6cikaFP"
            />
        <StyledLanderMindfulnessCollection>
          {/* <SleepCopy title="Permission to Reset Sleep" fullPriceWithDecimal="77.99" salePriceWithDecimal="37.77" sku="price_1JycJ0IfnzsCIRSOCoV8WNMU"/> */}
        </StyledLanderMindfulnessCollection>
      </SectionSingleProduct>

      <div>
        <PromoPatSection id="permissioning-offer" ref={ref1}>
          <h1>Special offer</h1>
          <PermissioningProductPromo posts={patPrograms.edges} current={productAnxiety.id}/>
        </PromoPatSection>
      </div>


        {/* <span>24 hour offer </span> */}
          <SectionSingleProduct id="info-sleep">
            <SingleProductLanderSituLiveOffer 
              title={productSleep.title}
              uri={productSleep.uri}
              excerpt={productSleep.excerpt}
              featuredImage={productSleep.featuredImage}
              content={productSleep.content}
              sale={productSleep.productData.pricesale}
              fullPrice={fullPriceSleep}
              salePrice={salePriceSleep}
              sku={fullPriceSleep}
              id={productSleep.id}
              tag={productSleep.productsTag}
              showShareTools={false}
              stripeBuyNowURL="https://buy.stripe.com/eVaeUZ7jh4wM2HK8xI"
            />
        <StyledLanderMindfulnessCollection>
          {/* <SleepCopy title="Permission to Reset Sleep" fullPriceWithDecimal="77.99" salePriceWithDecimal="37.77" sku="price_1JycJ0IfnzsCIRSOCoV8WNMU"/> */}
        </StyledLanderMindfulnessCollection>
      </SectionSingleProduct>
      <StyledSiteNotificationTimeDuration isNotification={isNotification}>
        <p>24 hour sale <AnchorLink className="anchor-offer-link" to={`/situlive#permissioning-offer`}>Click here</AnchorLink></p>
        
        
      </StyledSiteNotificationTimeDuration>
    </LayoutNoStripe>
    </>
  )
}

export default SituLiveOffer1

export const pageQuery = graphql`
query SituLiveOffer {
    # selecting the current post by id
    productAnxiety: wpProduct(id: {eq: "cG9zdDo3MjM0"}) {
      id
      title
      content
      uri
      excerpt
      productData {
        linkToAmazon
        pricefull
        pricesale
        stripefullprice
        stripesaleprice
      }
      productsTag {
            nodes {
              name
              slug
            }
          }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 975
                quality: 100
                placeholder: TRACED_SVG
                breakpoints: [320, 720, 1024, 1600]
              )
            }
            publicURL
          }
        }
      }
    }

    productSleep: wpProduct(id: {eq: "cG9zdDo3NzY5"}) {
      id
      title
      content
      uri
      excerpt
      productData {
        linkToAmazon
        pricefull
        pricesale
        stripefullprice
        stripesaleprice
      }
      productsTag {
            nodes {
              name
              slug
            }
          }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 975
                quality: 100
                placeholder: TRACED_SVG
                breakpoints: [320, 720, 1024, 1600]
              )
            }
            publicURL
          }
        }
      }
    }
    fullPriceAnxiety: allStripePrice(filter: {active: {eq: true}, id: {eq: "price_1KWRU7IfnzsCIRSOydEE5ERY" }}) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          product {
            id
            name
            active
            images
          }
        }
      }
    }

    salePriceAnxiety: allStripePrice(filter: {active: {eq: true}, id: {eq: "price_1JycJ0IfnzsCIRSOCoV8WNMU" }}) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          product {
            id
            name
            active
            images
          }
        }
      }
    }

    fullPriceSleep: allStripePrice(filter: {active: {eq: true}, id: {eq: "price_1KWRU7IfnzsCIRSOydEE5ERY" }}) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          product {
            id
            name
            active
            images
          }
        }
      }
    }

    salePriceSleep: allStripePrice(filter: {active: {eq: true}, id: {eq: "price_1KKL00IfnzsCIRSOqPlOlVFI" }}) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          product {
            id
            name
            active
            images
          }
        }
      }
    }

    patPrograms:   allWpProduct(filter: {productsTag: {nodes: {elemMatch: {name: {eq: "sleep-anxiety"}}}}}) {
      edges {
        node {
          title
          uri
          id
          excerpt
          productsTag {
            nodes {
              name
            }
          }
          productData {
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`
